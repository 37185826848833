export default function arrContains(where: string[], what: string[]): boolean {
  if (!what || what.length < 1) return false
  const newArray: { [key: string]: boolean } = {}
  for (let i = 0, l = where?.length; i < l; i++) {
    newArray[where[i]] = true
  }
  for (let i = 0, l = what.length; i < l; i++) {
    if (newArray[what[i]]) {
      return true
    }
  }
  return false
}
