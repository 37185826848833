import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from '@/App.vue'
import router from '@/router'

// import * as Sentry from "@sentry/vue";

const app = createApp(App)

// Sentry.init({
//   app,
//   dsn: "https://6151bbe5a92f19af56ddad000b296581@sentry.onestep.ru/3",
//   integrations: [
//     Sentry.browserTracingIntegration({ router }),
//     Sentry.replayIntegration({
//       unmask: [".sentry-unmask, .ant-notification-notice-message, [data-sentry-unmask]"],
//       unblock: [".sentry-unblock, [data-sentry-unblock]"],
//       mask: [".sentry-mask, [data-sentry-mask]"]
//     }),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

app.use(createPinia())
.use(router)
.mount('#app')