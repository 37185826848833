<script setup lang="ts">
import { computed, watch } from 'vue'
import { RouterView } from 'vue-router'
import { useRoute, useRouter } from 'vue-router'
import qs from 'qs'
import { useAppStore } from './stores/app'
import { storeToRefs } from 'pinia'
import StyleLoader from '@/styleLoader.vue'

const route = useRoute()
const router = useRouter()
const appStore = useAppStore()
const routeTitle = computed(() => route.meta["title"])
const item_id = computed(() => route.params.item_id)
const user_id = computed(() => route.params.user_id)
const currentRoute = computed(() => route)
const { authToken } = storeToRefs(appStore)

// watch page title change
watch(
  [routeTitle, item_id, user_id],
  ([routeTitle, item_id, user_id]) => {
    if (item_id) {
      (document.title = `${routeTitle}` + item_id)
    } else if (user_id) {
      (document.title = `${routeTitle}` + user_id)
    } else {
      (document.title = `${routeTitle}`)
    }
  },
)

async function init() {
  if(!authToken.value) await appStore.logout();

  try {
    await appStore.loadCurrentAccount();
  } catch (error) {
    // appStore.showFetchingError();
    return;
  }
}

init();

watch(authToken, authToken => {
  if (authToken) {
    const query = qs.parse(currentRoute.value.fullPath.split('?')[1], {
      ignoreQueryPrefix: true,
    })

    if (query.redirect) {
      router.push(query.redirect as string);
    }
  }
})

</script>

<template>
  <StyleLoader />
  <RouterView />
</template>


