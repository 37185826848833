import axios from 'axios';

const env = import.meta.env;

export const getUploadPath = () => {
  const hostnameArray = window.location.hostname.split('.');
  const numberOfSubdomains = hostnameArray.length - 2;
  let domain;
  if (env.VITE_APP_BACKEND_LOCAL) {
    domain = env.VITE_APP_BACKEND_LOCAL
    return domain.replace('/ux', '');
  } else {
    domain = hostnameArray.length === 2 ? window.location.hostname : hostnameArray.slice(numberOfSubdomains).join('.');
    return env.VITE_APP_API_ROOT + domain
  }
};

export function dBaseURL() {
  const hostnameArray = window.location.hostname.split('.');
  const numberOfSubdomains = hostnameArray.length - 2;
  let domain;
  if (env.VITE_APP_BACKEND_LOCAL) {
    domain = env.VITE_APP_BACKEND_LOCAL
    return domain
  } else {
    domain = hostnameArray.length === 2 ? window.location.hostname : hostnameArray.slice(numberOfSubdomains).join('.');
    return env.VITE_APP_API_ROOT + domain + env.VITE_APP_BACKEND_URL
  }
}

const instance = axios.create({
  baseURL: dBaseURL(),
});

export default instance;
