import { createRouter, createWebHistory } from 'vue-router'
import { useAppStore } from '@/stores/app'
import { storeToRefs } from 'pinia'
import qs from 'qs'
import { Button, notification } from 'ant-design-vue'
import { h } from 'vue'

const router = createRouter({
  history: createWebHistory(),
  stringifyQuery(query) {
    const result =  qs.stringify(query, {encode: false});
    return result ? result : '';
  },
  routes: [
    { 
      path: '/',
      name: 'home',
      redirect: '/dashboard',
      component: () => import('@/layouts/main/MainLayout.vue'),
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        {
          path: '/dashboard',
          meta: { title: 'Dashboard' },
          component: () => import('@/views/dashboard/DashBoard.vue'),
        },
        // Customers
        {
          path: '/customers',
          meta: {
            title: 'Customers',
            rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_CUSTOMER_RW'],
          },
          component: () => import('@/views/customers/CustomerList.vue'),
        },
        {
          path: '/customers/:item_id',
          meta: {
            title: 'Customer ID: ',
            rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_CUSTOMER_RW'],
          },
          component: () => import('@/views/customers/CustomerWrap.vue'),
        },
        // Tools
          // Retries
          {
            path: '/tools/retries',
            meta: {
              title: 'Retry Primary Sales',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/tools/retries/ToolsRetries.vue'),
          },
          // Order Debugger
          {
            path: '/tools/order-debugger',
            meta: {
              title: 'Order Debugger',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/tools/orders_debugger/OrderDebuggerList.vue'),
          },
          {
            path: '/tools/order-debugger/:item_id',
            meta: {
              title: 'Debug Order ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN'],
              prev: '/tools/order-debugger'
            },
            component: () => import('@/views/tools/orders_debugger/OrderDebuggerCard.vue'),
          },
          // CC Search
          {
            path: '/tools/cc-search',
            meta: {
              title: 'CC Search',
              rolesRW: [
                'ROLE_SUPER_ADMIN',
                'ROLE_UX_TOOLS_CC_SEARCH_VOID',
                'ROLE_UX_TOOLS_CC_SEARCH_CHARGEBACK',
                'ROLE_UX_TOOLS_CC_SEARCH_RDR_REFUND',
              ],
            },
            component: () => import('@/views/tools/cc_search/CreditCardSearch.vue'),
          },
          // Rebill Activation
          {
            path: '/tools/rebill-activation',
            meta: {
              title: 'Rebill Activation',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/tools/rebill_activation/RebillActivation.vue'),
          },
          // Order Details
          {
            path: '/tools/order-details',
            meta: {
              title: 'Order Details',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/tools/orders_details/OrderDetailsList.vue'),
          },
          // Failed Refund Details
          {
            path: '/tools/failed-refund-details',
            meta: {
              title: 'Failed Refund Details',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/tools/failed_refund_details/FailedRefundDetailsList.vue'),
          },
          // BSB Refunds
          {
            path: '/tools/bsb-refunds',
            meta: {
              title: 'BSB Refunds',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/tools/bsb_refunds/BsbRefunds.vue'),
          },
          // Orders Untracked
          {
            path: '/tools/orders-untracked',
            meta: {
              title: 'Orders Untracked',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/tools/orders_untracked/OrderUntrackedList.vue'),
          },
          // Contact Fix Tool
          {
            path: '/tools/order-contacts',
            meta: {
              title: 'Contact Fix Tool',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/tools/contact_fix_tool/ContactFixTool.vue'),
          },
          // Email Fix Tool
          {
            path: '/tools/order-emails',
            meta: {
              title: 'Email Fix Tool',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/tools/email_fix_tool/EmailFixTool.vue'),
          },
          // Address Fix Tool
          {
            path: '/tools/order-addresses',
            meta: {
              title: 'Address Fix Tool',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/tools/address_fix_tool/AddressFixTool.vue'),
          },
          // Tracking Control
          {
            path: '/tools/tracking-control',
            meta: {
              title: 'Tracking Control',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/tools/tracking_control/TrackingControlList.vue'),
          },
          {
            path: '/tools/tracking-control/:id',
            meta: {
              title: 'Tracking Statistics',
              rolesRW: ['ROLE_SUPER_ADMIN'],
              prev: '/tools/tracking-control'
            },
            component: () => import('@/views/tools/tracking_control/TrackingControl.vue'),
          },
          {
            path: '/tools/monetization-tool',
            meta: {
              title: 'Monetization Tool',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/tools/monetization_tool/MonetizationTool.vue'),
          },
        // Merchants
          // Mids
          {
            path: '/merchants/mids',
            meta: {
              title: 'Mids',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_MID_RW'],
            },
            component: () => import('@/views/merchants/mids/MidList.vue'),
          },
          {
            path: '/merchants/mids/:item_id',
            meta: {
              title: 'Edit Mid ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_MID_RW'],
              prev: '/merchants/mids'
            },
            component: () => import('@/views/merchants/mids/MidCard.vue'),
          },
          {
            path: '/merchants/mids/create',
            meta: {
              title: 'Create Mid',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_MID_RW'],
              prev: '/merchants/mids'
            },
            component: () => import('@/views/merchants/mids/MidCard.vue'),
          },
          // Mids Detailed
          {
            path: '/merchants/mids-detailed',
            meta: {
              title: 'Mids Detailed',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_MID_RW'],
            },
            component: () => import('@/views/merchants/mids_detailed/MidDetailedList.vue'),
          },
          // Banks
          {
            path: '/merchants/banks',
            meta: {
              title: 'Banks',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_BANK_RW'],
            },
            component: () => import('@/views/merchants/banks/BankList.vue'),
          },
          {
            path: '/merchants/banks/:item_id',
            meta: {
              title: 'Edit Bank ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_BANK_RW'],
              prev: '/merchants/banks'
            },
            component: () => import('@/views/merchants/banks/BankCard.vue'),
          },
          {
            path: '/merchants/banks/create',
            meta: {
              title: 'Create Bank',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_BANK_RW'],
              prev: '/merchants/banks'
            },
            component: () => import('@/views/merchants/banks/BankCard.vue'),
          },
          // Corporations
          {
            path: '/merchants/corporations',
            meta: {
              title: 'Corporations',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_CORPORATION_RW'],
            },
            component: () => import('@/views/merchants/corporations/CorporationList.vue'),
          },
          {
            path: '/merchants/corporations/:item_id',
            meta: {
              title: 'Edit Corporation ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_CORPORATION_RW'],
              prev: '/merchants/corporations'
            },
            component: () => import('@/views/merchants/corporations/CorporationCard.vue'),
          },
          {
            path: '/merchants/corporations/create',
            meta: {
              title: 'Create Corporation',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_CORPORATION_RW'],
              prev: '/merchants/corporations'
            },
            component: () => import('@/views/merchants/corporations/CorporationCard.vue'),
          },
          // Gateways
          {
            path: '/merchants/gateways',
            meta: {
              title: 'Gateways',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_GATEWAY_RW'],
            },
            component: () => import('@/views/merchants/gateways/GatewayList.vue'),
          },
          {
            path: '/merchants/gateways/:item_id',
            meta: {
              title: 'Edit Gateway ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_GATEWAY_RW'],
              prev: '/merchants/gateways'
            },
            component: () => import('@/views/merchants/gateways/GatewayCard.vue'),
          },
          {
            path: '/merchants/gateways/create',
            meta: {
              title: 'Create Gateway',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_GATEWAY_RW'],
              prev: '/merchants/gateways'
            },
            component: () => import('@/views/merchants/gateways/GatewayCard.vue'),
          },
          // Fulfillment Services
          {
            path: '/merchants/fulfillment-services',
            meta: {
              title: 'Fulfillment Services',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_FULFILLMENT_RW'],
            },
            component: () => import('@/views/merchants/fulfillment/FulfillmentList.vue'),
          },
          {
            path: '/merchants/fulfillment-services/:item_id',
            meta: {
              title: 'Edit Fulfillment Service ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_FULFILLMENT_RW'],
              prev: '/merchants/fulfillment-services'
            },
            component: () => import('@/views/merchants/fulfillment/FulfillmentCard.vue'),
          },
          {
            path: '/merchants/fulfillment-services/create',
            meta: {
              title: 'Create Fulfillment Service',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_FULFILLMENT_RW'],
              prev: '/merchants/fulfillment-services'
            },
            component: () => import('@/views/merchants/fulfillment/FulfillmentCard.vue'),
          },
          // Monetization Settings
          {
            path: '/merchants/monetization-settings',
            meta: {
              title: 'Monetization Settings',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_MONETIZATION_RW'],
            },
            component: () => import('@/views/merchants/monetization_settings/MonetizationSettingsList.vue'),
          },
          {
            path: '/merchants/monetization-settings/:item_id',
            meta: {
              title: 'Edit Monetization Rules ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_MONETIZATION_RW'],
              prev: '/merchants/monetization-settings'
            },
            component: () => import('@/views/merchants/monetization_settings/MonetizationSettingsCard.vue'),
          },
          {
            path: '/merchants/monetization-settings/create',
            meta: {
              title: 'Create Monetization Rules',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_MERCHANTS_MONETIZATION_RW'],
              prev: '/merchants/monetization-settings'
            },
            component: () => import('@/views/merchants/monetization_settings/MonetizationSettingsCard.vue'),
          },
          // Monetization Routing
          {
            path: '/merchants/monetization-routing',
            meta: {
              title: 'Monetization Routing',
              rolesRW: ['ROLE_SUPER_ADMIN', 'ROLE_UX_MERCHANTS_MONETIZATION_ROUTING_RW'],
            },
            component: () => import('@/views/merchants/monetization_routing/MonetizationRouting.vue'),
          },
        // Products
          {
            path: '/products',
            meta: {
              title: 'Products',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_PRODUCT_RW'],
            },
            component: () => import('@/views/products/products/ProductList.vue'),
          },
          {
            path: '/products/:item_id',
            meta: {
              title: 'Edit Product ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_PRODUCT_RW'],
              prev: '/products'
            },
            component: () => import('@/views/products/products/ProductCard.vue'),
          },
          {
            path: '/products/create',
            meta: {
              title: 'Create Product',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_PRODUCT_RW'],
              prev: '/products'
            },
            component: () => import('@/views/products/products/ProductCard.vue'),
          },
          // Prices
          {
            path: '/products/prices',
            meta: {
              title: 'Prices',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_PRICE_RW'],
            },
            component: () => import('@/views/products/prices/PriceList.vue'),
          },
          {
            path: '/products/prices/:item_id',
            meta: {
              title: 'Edit Price ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_PRICE_RW'],
              prev: '/products/prices'
            },
            component: () => import('@/views/products/prices/PriceCard.vue'),
          },
          {
            path: '/products/prices/create',
            meta: {
              title: 'Create Price',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_PRICE_RW'],
              prev: '/products/prices'
            },
            component: () => import('@/views/products/prices/PriceCard.vue'),
          },
        // Campaigns
          {
            path: '/campaigns',
            meta: {
              title: 'Campaigns',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_CAMPAIGN_RW'],
            },
            component: () => import('@/views/campaigns/campaigns/CampaignList.vue'),
          },
          {
            path: '/campaigns/:item_id',
            meta: {
              title: 'Edit Campaign ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_CAMPAIGN_RW'],
              prev: '/campaigns'
            },
            component: () => import('@/views/campaigns/campaigns/CampaignCard.vue'),
          },
          {
            path: '/campaigns/create',
            meta: {
              title: 'Create Campaign',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_CAMPAIGN_RW'],
              prev: '/campaigns'
            },
            component: () => import('@/views/campaigns/campaigns/CampaignCard.vue'),
          },
          // Campaign Groups
          {
            path: 'campaigns/campaign-groups',
            meta: {
              title: 'Campaign Groups',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_CAMPAIGN_GROUP_RW'],
            },
            component: () => import('@/views/campaigns/groups/CampaignGroupList.vue'),
          },
          {
            path: '/campaigns/campaign-groups/:item_id',
            meta: {
              title: 'Edit Campaign Group ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_CAMPAIGN_GROUP_RW'],
              prev: '/campaigns/campaign-groups'
            },
            component: () => import('@/views/campaigns/groups/CampaignGroupCard.vue'),
          },
          {
            path: '/campaigns/campaign-groups/create',
            meta: {
              title: 'Create Campaign Group',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_CAMPAIGN_GROUP_RW'],
              prev: '/campaigns/campaign-groups'
            },
            component: () => import('@/views/campaigns/groups/CampaignGroupCard.vue'),
          },
        // Emails
          // Domains
          {
            path: '/emails/domains',
            meta: {
              title: 'Domains',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_DOMAIN_RW'],
            },
            component: () => import('@/views/emails/domains/DomainList.vue'),
          },
          {
            path: '/emails/domains/:item_id',
            meta: {
              title: 'Edit Domain ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_DOMAIN_RW'],
              prev: '/emails/domains'
            },
            component: () => import('@/views/emails/domains/DomainCard.vue'),
          },
          {
            path: '/emails/domains/create',
            meta: {
              title: 'Create Domain',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_DOMAIN_RW'],
              prev: '/emails/domains'
            },
            component: () => import('@/views/emails/domains/DomainCard.vue'),
          },
          // Servers
          {
            path: '/emails/servers',
            meta: {
              title: 'Servers',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_SERVER_RW'],
            },
            component: () => import('@/views/emails/servers/ServerList.vue'),
          },
          {
            path: '/emails/servers/:item_id',
            meta: {
              title: 'Edit Server ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_SERVER_RW'],
              prev: '/emails/servers'
            },
            component: () => import('@/views/emails/servers/ServerCard.vue'),
          },
          {
            path: '/emails/servers/create',
            meta: {
              title: 'Create Server',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_SERVER_RW'],
              prev: '/emails/servers'
            },
            component: () => import('@/views/emails/servers/ServerCard.vue'),
          },
          // Outboxes
          {
            path: '/emails/outboxes',
            meta: {
              title: 'Outboxes',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_OUTBOXES_RW'],
            },
            component: () => import('@/views/emails/outboxes/OutboxList.vue'),
          },
          {
            path: '/emails/outboxes/:item_id',
            meta: {
              title: 'Edit Outbox ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_OUTBOXES_RW'],
              prev: '/emails/outboxes'
            },
            component: () => import('@/views/emails/outboxes/OutboxCard.vue'),
          },
          {
            path: '/emails/outboxes/create',
            meta: {
              title: 'Create Outbox',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_OUTBOXES_RW'],
              prev: '/emails/outboxes'
            },
            component: () => import('@/views/emails/outboxes/OutboxCard.vue'),
          },
          // Email Groups
          {
            path: '/emails/email-groups',
            meta: {
              title: 'Email Groups',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_GROUP_RW'],
            },
            component: () => import('@/views/emails/groups/EmailGroupList.vue'),
          },
          {
            path: '/emails/email-groups/:item_id',
            meta: {
              title: 'Edit Email Group ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_GROUP_RW'],
              prev: '/emails/email-groups'
            },
            component: () => import('@/views/emails/groups/EmailGroupCard.vue'),
          },
          {
            path: '/emails/email-groups/create',
            meta: {
              title: 'Create Email Group',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_GROUP_RW'],
              prev: '/emails/email-groups'
            },
            component: () => import('@/views/emails/groups/EmailGroupCard.vue'),
          },
          // Templates
          {
            path: '/emails/templates',
            meta: {
              title: 'Templates',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_TEMPLATES_RW'],
            },
            component: () => import('@/views/emails/templates/TemplateList.vue'),
          },
          {
            path: '/emails/templates/:item_id',
            meta: {
              title: 'Edit Template ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_TEMPLATES_RW'],
              prev: '/emails/templates'
            },
            component: () => import('@/views/emails/templates/TemplateCard.vue'),
          },
          {
            path: '/emails/templates/create',
            meta: {
              title: 'Create Template',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_EMAIL_TEMPLATES_RW'],
              prev: '/emails/templates'
            },
            component: () => import('@/views/emails/templates/TemplateCard.vue'),
          },
          // Threads
          {
            path: '/emails/threads',
            meta: {
              title: 'Threads',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/emails/threads/EmailThreads.vue'),
          },
          // Escalated Threads
          {
            path: '/emails/escalated-threads',
            meta: {
              title: 'Escalated Threads',
              rolesRW: ['ROLE_SUPER_ADMIN'],
              type: 'escalated'
            },
            component: () => import('@/views/emails/threads/EmailThreads.vue'),
          },
          // Manager Threads
          {
            path: '/emails/manager-threads',
            meta: {
              title: 'Manager Threads',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/emails/manager_threads/ManagerThreads.vue'),
          },
          // Manager Threads
          {
            path: '/emails/manager-threads/:item_id',
            meta: {
              title: 'Manager Threads',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/emails/manager_threads/ManagerThreads.vue'),
          },
        // Imports
          // Bad Address
          {
            path: '/import/chargeback',
            meta: {
              title: 'Import Chargeback',
              rolesRW: ['ROLE_SUPER_ADMIN', 'ROLE_UX_IMPORT_CHARGEBACK_FULL_VIEW'],
            },
            component: () => import('@/views/imports/chargeback/ImportChargeBack.vue'),
          },
          // Chargeback
          {
            path: '/import/bad-address-orders',
            meta: {
              title: 'Import Bad Address Orders',
              rolesRW: ['ROLE_SUPER_ADMIN', 'ROLE_UX_IMPORT_BAD_ADDRESS_FULL_VIEW'],
            },
            component: () => import('@/views/imports/bad_address/ImportBadAddress.vue'),
          },
          // Returns
          {
            path: '/import/returns',
            meta: {
              title: 'Import Returns',
              rolesRW: ['ROLE_SUPER_ADMIN', 'ROLE_UX_IMPORT_BAD_ADDRESS_FULL_VIEW'],
            },
            component: () => import('@/views/imports/returns/ImportReturns.vue'),
          },
          // Tracking
          {
            path: '/import/tracking',
            meta: {
              title: 'Import Tracking',
              rolesRW: ['ROLE_SUPER_ADMIN', 'ROLE_UX_IMPORT_TRACKING_FULL_VIEW'],
            },
            component: () => import('@/views/imports/tracking/ImportTracking.vue'),
          },
        // Exports
          // Export Orders
          {
            path: '/export/orders',
            meta: {
              title: 'Export Orders',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/exports/export_orders/ExportOrders.vue'),
          },
          // Order Export Balancer
          {
            path: '/export/order-export-balancer',
            meta: {
              title: 'Order Export Balancer',
              rolesRW: ['ROLE_SUPER_ADMIN', 'ROLE_UX_EXPORT_ORDER_BALANCER_RW'],
            },
            component: () => import('@/views/exports/order_export_balancer/OrderExportBalancer.vue'),
          },
        // Stats
          // Affiliate Stats
          {
            path: '/stats/affiliate-stats',
            meta: {
              title: 'Affiliate Stats',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/stats/affiliate/AffiliateStats.vue'),
          },
          // AOV Stats
          {
            path: '/stats/aov',
            meta: {
              title: 'AOV Stats',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/stats/aov/AOVStats.vue'),
          },
          // Decline Stats
          {
            path: '/stats/decline-stats',
            meta: {
              title: 'Decline Stats',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/stats/decline/DeclineStats.vue'),
          },
          // Monetization Stats
          {
            path: '/stats/monetization-stats',
            meta: {
              title: 'Monetization Stats',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/stats/monetization/MonetizationStats.vue'),
          },
          // General Stats
          {
            path: '/stats/general-stats',
            meta: {
              title: 'General Stats',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/stats/general/GeneralStats.vue'),
          },
          // Tracking Report
          {
            path: '/stats/tracking-report',
            meta: {
              title: 'Tracking Report',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/stats/tracking_report/TrackingReportStats.vue'),
          },
          // Detailed Tracking Report
          {
            path: '/stats/detailed-tracking-report',
            meta: {
              title: 'Detailed Tracking Report',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/stats/detailed_tracking_report/DetailedTrackingReportStats.vue'),
          },
          // Product Stats
          {
            path: '/stats/products',
            meta: {
              title: 'Product Stats',
              rolesRW: ['ROLE_SUPER_ADMIN'],
            },
            component: () => import('@/views/stats/products/ProductStats.vue'),
          },
        // System
          // Users
          {
            path: '/system/users',
            meta: {
              title: 'Users',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_USER_RW'],
            },
            component: () => import('@/views/system/users/UserList.vue'),
          },
          {
            path: '/system/users/:item_id',
            meta: {
              title: 'Edit User ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_USER_RW'],
              prev: '/system/users'
            },
            component: () => import('@/views/system/users/UserCard.vue'),
          },
          {
            path: '/system/users/create',
            meta: {
              title: 'Create User',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_USER_RW'],
              prev: '/system/users'
            },
            component: () => import('@/views/system/users/UserCard.vue'),
          },
          // User Groups
          {
            path: '/system/user-groups',
            meta: {
              title: 'User Groups',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_USER_RW'],
            },
            component: () => import('@/views/system/groups/UserGroupList.vue'),
          },
          {
            path: '/system/user-groups/:item_id',
            meta: {
              title: 'Edit User Group ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_USER_RW'],
              prev: '/system/user-groups'
            },
            component: () => import('@/views/system/groups/UserGroupCard.vue'),
          },
          {
            path: '/system/user-groups/create',
            meta: {
              title: 'Create User Group',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_USER_RW'],
              prev: '/system/user-groups'
            },
            component: () => import('@/views/system/groups/UserGroupCard.vue'),
          },
          //Countries
          {
            path: '/system/countries',
            meta: {
              title: 'Countries',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_COUNTRY_RW'],
            },
            component: () => import('@/views/system/countries/CountryList.vue'),
          },
          {
            path: '/system/countries/:item_id',
            meta: {
              title: 'Edit Country ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_COUNTRY_RW'],
              prev: '/system/countries'
            },
            component: () => import('@/views/system/countries/CountryCard.vue'),
          },
          {
            path: '/system/countries/create',
            meta: {
              title: 'Create Country',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_COUNTRY_RW'],
              prev: '/system/countries'
            },
            component: () => import('@/views/system/countries/CountryCard.vue'),
          },
          //Currencies
          {
            path: '/system/currencies',
            meta: {
              title: 'Currencies',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_CURRENCY_RW'],
            },
            component: () => import('@/views/system/currencies/CurrencyList.vue'),
          },
          {
            path: '/system/currencies/:item_id',
            meta: {
              title: 'Edit Currency ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_CURRENCY_RW'],
              prev: '/system/currencies'
            },
            component: () => import('@/views/system/currencies/CurrencyCard.vue'),
          },
          {
            path: '/system/currencies/create',
            meta: {
              title: 'Create Currency',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_CURRENCY_RW'],
              prev: '/system/currencies'
            },
            component: () => import('@/views/system/currencies/CurrencyCard.vue'),
          },
          //Proxies
          {
            path: '/system/proxies',
            meta: {
              title: 'Proxies',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_PROXY_RW'],
            },
            component: () => import('@/views/system/proxy/ProxyList.vue'),
          },
          {
            path: '/system/proxies/:item_id',
            meta: {
              title: 'Edit Proxy ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_PROXY_RW'],
              prev: '/system/proxies'
            },
            component: () => import('@/views/system/proxy/ProxyCard.vue'),
          },
          {
            path: '/system/proxies/create',
            meta: {
              title: 'Create Proxy',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_PROXY_RW'],
              prev: '/system/proxies'
            },
            component: () => import('@/views/system/proxy/ProxyCard.vue'),
          },
          // Sites
          {
            path: '/system/sites',
            meta: {
              title: 'Sites',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_SITE_RW'],
            },
            component: () => import('@/views/system/sites/SiteList.vue'),
          },
          {
            path: '/system/sites/:item_id',
            meta: {
              title: 'Edit Site ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_SITE_RW'],
              prev: '/system/sites'
            },
            component: () => import('@/views/system/sites/SiteCard.vue'),
          },
          {
            path: '/system/sites/create',
            meta: {
              title: 'Create Site',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_SITE_RW'],
              prev: '/system/sites'
            },
            component: () => import('@/views/system/sites/SiteCard.vue'),
          },
          // Banlist
          {
            path: '/system/ban-list',
            meta: {
              title: 'Ban list',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_BAN_RW'],
            },
            component: () => import('@/views/system/banlist/BanList.vue'),
          },
          {
            path: '/system/ban-list/:item_id',
            meta: {
              title: 'Edit Ban ID: ',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_BAN_RW'],
              prev: '/system/ban-list'
            },
            component: () => import('@/views/system/banlist/BanCard.vue'),
          },
          {
            path: '/system/ban-list/create',
            meta: {
              title: 'Create Ban',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_BAN_RW'],
              prev: '/system/ban-list'
            },
            component: () => import('@/views/system/banlist/BanCard.vue'),
          },
          // History
          {
            path: '/system/history',
            meta: {
              title: 'History',
              rolesRW: ['ROLE_SUPER_ADMIN','ROLE_UX_HISTORY'],
            },
            component: () => import('@/views/system/history/HistoryList.vue'),
          },
          // Profile
          {
            path: '/system/profile',
            meta: { title: 'My Profile' },
            component: () => import('@/views/system/profile/ProfilePage.vue'),
          },
      ],
    },
    // System Pages
    {
      path: '/auth',
      component: () => import('@/layouts/auth/AuthLayout.vue'),
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          name: 'route404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('@/views/errors/ErrorNotFound.vue'),
        },
        {
          path: '/auth/login',
          name: 'login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('@/views/auth/LoginPage.vue'),
        },
      ],
    },
    // Redirect to 404
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'route404' },
    },
  ]
});

router.beforeEach((to, _from, next) => {
  const appStore = useAppStore();
  const { authToken } = storeToRefs(appStore);

  if (to.meta.authRequired) {
    (async () => {
      await appStore.updateHeaders(to);
      await appStore.checkRW(to);
    })();
  }

  if (to.meta.authRequired && !authToken.value ) {
    next({
      path: '/auth/login',
    });
  }
  else next();
});

function reload(key: string, path: string) {
  notification.close(key);
  window.location.href = path;
}

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module'))
  {
    (async () => {
      const key = `open${Date.now()}`;
      notification.warning({
        message: `We have improved the code!`,
        description: 'Please reload the page for new improvements to take affect.',
        style: {
          width: '600px',
          marginLeft: `${335 - 600}px`,
        },
        btn: () =>
          h(
            Button,
            {
              type: 'primary',
              onClick: () => reload(key, to.fullPath),
            },
            { default: () => 'Reload' },
          ),
        key,
        duration: null,
        onClose: close,
      });
    })();
  }
});

export default router
