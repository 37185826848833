<script setup lang="ts">

// antd core & themes styles
import 'ant-design-vue/lib/style/index.less'
import "ant-design-vue/lib/message/style/index.css";
import "ant-design-vue/lib/notification/style/index.css";
// import "ant-design-vue/lib/radio/style/index.css";
import "codemirror/theme/dracula.css";
// ui vendors styles
import '@/styles/vendors/antd/style.scss'
import '@/styles/vendors/nprogress/style.scss'

// ui styles
import '@/styles/core.scss'
import '@/styles/measurements.scss'
import '@/styles/colors.scss'
import '@/styles/utils.scss'
import '@/styles/layout.scss'
import '@/styles/router.scss'
import '@/tables.css';
document.querySelector('html')?.setAttribute('data-ui-theme', 'default')
</script>

<template>
  <div />
</template>