import { defineStore } from 'pinia'
import { jwtDecode } from 'jwt-decode'
import api from '@/services/api'
import router from "@/router"
import { notification } from 'ant-design-vue'
import { checkRWByRoute } from "@/utils/checkRWByRoute.ts";
import NProgress from 'nprogress'

interface AppSettings {
  authToken: string | null,
  refreshToken: string | null,
  id: string | null,
  name: string | null,
  username: string | null,
  email: string | null,
  phone: string | null,
  role: string | null,
  roles: string[] | null,
  timezone: string | null,
  logo: string | null,
  favicon: string | null,
  loading: boolean,
  isRW: boolean,
  apiToken: string | null,
}

function setAuthorizationHeader(authToken: string) {
  api.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
}

function setRequestedUrlHeader(url: string) {
  api.defaults.headers.common['Requested-Url'] = url;
}

function removeRequestedUrlHeader() {
  delete api.defaults.headers.common['Requested-Url'];
}

function removeAuthorizationHeader() {
  delete api.defaults.headers.common['Authorization'];
}

export const useAppStore = defineStore('app', {
  state: (): AppSettings => {
    const authToken = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh_token');
    const apiToken = localStorage.getItem('api_token');
    const favicon = localStorage.getItem('favicon');
    const logo = localStorage.getItem('logo');

    if (authToken !== null) {
      setAuthorizationHeader(authToken);
    }

    return {
      authToken,
      refreshToken,
      apiToken,
      favicon,
      logo,
      id: null,
      name: null,
      username: null,
      email: null,
      phone: null,
      role: null,
      roles: null,
      timezone: null,
      loading: true,
      isRW: false,
    };
  },
  actions: {
    async login(username: string, password: string) {
      try {
        const  { data: {token, refresh_token, logo, favicon} } = await api.post('/login_check', {
          username,
          password,
        })

        if (typeof token === 'string') {
          setAuthorizationHeader(token);
          localStorage.setItem('token', token);
          localStorage.setItem('refresh_token', refresh_token);
          localStorage.setItem('logo', logo);
          localStorage.setItem('favicon', favicon);
          this.logo = logo;
          this.favicon = favicon;
          this.authToken = token;
          this.refreshToken = refresh_token;
          const { data } = await api.get('/user/profile/get-apikey');
          localStorage.setItem('api_token', data.token);
          this.apiToken = data.token;
          await this.loadCurrentAccount();
          await router.push('/');
          notification.success({
            message: 'Logged In',
            description: 'You have successfully logged in!',
          })
        }
      } catch (e: any) {
        notification.warning({
          message: 'Login failed, please try again!',
          description: e.response?.data?.message,
        })
        throw new Error(e)
      }
    },
    async loadCurrentAccount() {
      if (this.authToken) {
        const {
          username,
          name,
          email,
          phone,
          role,
          roles,
          timezone,
        }: AppSettings = await jwtDecode(this.authToken);
          this.name = name;
          this.username = username;
          this.email = email;
          this.phone = phone;
          this.role = role;
          this.roles = roles;
          this.timezone = timezone;
      }
    },
    async checkRW (route: any) {
      this.isRW = checkRWByRoute(this.roles || [], route);
    },
    async updateHeaders(route: any) {
      setRequestedUrlHeader(route.href);
    },
    async fetchingError(error :any, route?: boolean) {
      const { response } = error;
      if (response?.status === 401) {
        console.log('token is expired');
        await this.refreshAuthToken();
      } else if(response?.status === 403) {
        if (route) {
          notification.warning({
            message: `An error occurred!`,
            description: response?.data?.message || response.data['hydra:description'] || 'Something went wrong',
          })
          await this.refreshAuthToken()
          await router.push('/dashboard');
          NProgress.done();
        }
        throw new Error(error);
      } else if (response?.status === 400) {
        notification.warning({
          message: response.data['hydra:description'] || response?.data?.message,
        })
        throw new Error(error);
      } else {
        notification.warning({
          message: `An error occurred!`,
          description: response?.data?.message || response.data['hydra:description'] || response?.data?.detail || 'Something went wrong',
        })
        throw new Error(error);
      }
    },
    async refreshAuthToken() {
      try {
        removeAuthorizationHeader();
        console.log('trying refresh token');
        const payload = new URLSearchParams(Object.entries({refresh_token: this.refreshToken as string})).toString();
        const  { data: {token, refresh_token} } = await api.post('/token/refresh', payload,  {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        if (typeof token === 'string') {
          setAuthorizationHeader(token);
          localStorage.setItem('token', token);
          localStorage.setItem('refresh_token', refresh_token);
          this.authToken = token;
          this.refreshToken = refresh_token;
          await this.loadCurrentAccount();
          console.log('token refresh successful');
        }
      } catch (e:any) {
        console.log('token refresh failed');
        await this.logout();
        throw new Error(e);
      }
    },
    async logout() {
      removeAuthorizationHeader();
      removeRequestedUrlHeader();
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('api_token');
      this.id = null;
      this.name = null;
      this.username = null;
      this.email = null;
      this.phone = null;
      this.roles = null;
      this.timezone = null;
      this.logo = null;
      this.favicon = null;
      this.loading = true;
      await router.push('/auth/login');
      NProgress.done();
    }
  },
});
